<template>
  <v-sheet :color="item.color" class="pa-5 border poppins" :style="`border: 1px solid ${item.outline}; color: ${item.outline}`">
    <div class="text-right f23 fw600">
      {{item.count}}
    </div>
    <div class="text-right fw600">
      {{item.title}}
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['item']
}
</script>